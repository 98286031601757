import './App.css';
import Navigationbar from './navbar/Navbar';
import {BrowserRouter} from 'react-router-dom';
import React, { useRef } from 'react';
// import video2 from './whitesmoke.mov';
import video1 from './second.mp4';
function App() {
  const videoRef = useRef(null);
  return (
    <BrowserRouter>
    <div className='App'>
    <video className="background-video" src={video1} autoPlay loop={true} muted={true}></video>
           
    <Navigationbar />
   
   </div>
   </BrowserRouter>
  );
}

export default App;
